import "./liveChat.css";
import React, { useState, useEffect, useRef } from 'react';

import firebase from 'firebase/compat/app';
import {auth, firestore} from '../../../firebase.js';

import emailjs from 'emailjs-com';

import {useAuthState} from "react-firebase-hooks/auth";
import {useCollection} from "react-firebase-hooks/firestore";

import {ReactComponent as LiveChatIcon } from "./icons/liveChat.svg";
import signOut from "./icons/Sign Out.svg";
import arrowDown from "./icons/arrowDown.svg";

function sendMail(toEmail, fromEmail, message, fromName, toName){
    emailjs.init('f0mStwvUnAehr6xyA');

    var params = {
        to: toEmail,
        from: fromEmail,
        message: message,
        from_name: fromName,
        to_name: toName
    }

    var serviceId = "service_m2vlkgp";
    var templateId = "template_hr7e2ba";
    
    emailjs.send(serviceId, templateId, params)
    .then( res => {
        console.log("Notification sent")
    })
    .catch();


}





function ChatMessage(props){
    const currentUser = auth.currentUser.uid;
    const admin = currentUser === "5Drmu8wi54VwtelKQRAhWkbyM1r1";
    
    // const uid = props.id;
    
    const {text, sentByAdmin, photoURL} = props.message;
    //createdAt ^

    // const messageClass = uid === auth.currentUser.uid ? 'sent' : 'recieved';
    let messageClass = "";

    if(sentByAdmin && admin){
        messageClass = 'sent';

    }else if(!sentByAdmin && !admin){ 
        messageClass = 'sent';

    }else{
        messageClass = 'recieved';
    }

    return(
        <div className={`message ${messageClass}`}>
            <img className = "accountImg" src={photoURL} alt="PP" />
            <p>{text}</p>
        </div>
    );
}

function ChatRoom({uid}){
    
    const dummy = useRef();

    useEffect(() => {
        dummy.current.scrollIntoView({behavior: 'smooth'});
    });

    const currentuid = auth.currentUser.uid;
    
    const admin = currentuid === "5Drmu8wi54VwtelKQRAhWkbyM1r1";

    const userDocRef = firestore.collection('chat').doc(uid);
    const messagesRef = userDocRef.collection('messages'); 

    const query = messagesRef.orderBy('createdAt').limit(25);

    const [value] = useCollection(query);
    const [user] = useAuthState(auth);

    let photoURL = "";
    if(user){
        photoURL = user.photoURL;
    }

    let fromName = "";
    const [toName, setName] = useState("Samuel Yuabov");
    const [toEmail, setToEmail] = useState("syuabov03@gmail.com");
    let fromEmail = "";


    if(admin){
        userDocRef.get().then((doc) => {
            if (doc.exists) {
                const data = doc.data();
                setToEmail(data.email);
                setName(data.name);
             ;
            }
          }).catch((error) => {
            console.error('Error getting document:', error);
          });

        fromEmail = "syuabov03@gmail.com";
        fromName = "Samuel Yuabov"
        
    }else{
        fromEmail = user.email;
        fromName = user.displayName;
    }

    const [formValue, setFormValue] = useState("");

    const sendMessage = async (e) => {
        e.preventDefault();

        const currentTime = firebase.firestore.FieldValue.serverTimestamp();

        await userDocRef.update({
            latestMessage: currentTime,
        });
        
        await messagesRef.add({
            text: formValue,
            createdAt: currentTime,
            sentByAdmin: admin,
            photoURL: photoURL,
        });

        if(admin){
            sendMail(toEmail, fromEmail, formValue, fromName, toName);
        }

        setFormValue('');

        dummy.current.scrollIntoView({behavior: 'smooth'});

        
    }

    

    return (
        <div className="text_message_container">
            {value && value.docs.map(msg => (
                <>
                    <ChatMessage id={uid} message={msg.data()} />
                </>

            ))} 
            <div ref={dummy}></div>
            <form onSubmit={sendMessage} className="sendform">
                
                <input type="text" placeholder="Type a message..." value={formValue} onChange={(e) => setFormValue(e.target.value)}/>

                {formValue ? (<button type="submit">Send</button>) : (<button type="submit" disabled>Send</button>)}
            </form>
        </div>

    );



}


function AllMessages(){

    const messagesRef = firestore.collection('chat');
    const query = messagesRef.orderBy('latestMessage');

    const [snapshot] = useCollection(query);
    const [selectedUser, setSelectedUser] = useState(null);

    const distinctUids = [...new Map(snapshot?.docs.map(doc => [doc.id, doc.data().name])).entries()];
    
    // if(distinctUids){
    //     console.log(distinctUids)
    // }

    const selectUser = (uid) => {
        setSelectedUser(uid);
    }   

    return(
        <div>
            <div className="main_message_container">
                <div className="user_display">
                    {distinctUids && distinctUids.map(([uid, name]) => (
                        <button key={uid} onClick={() => selectUser(uid)}>{name}</button>
                    ))}

                </div>
                <div className="message_display">
                    {selectedUser && <ChatRoom uid={selectedUser}/>}
                </div>
            </div>
        </div>  
    );
}

function SignIn(){

    const signIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider);
    }


    return(
        <div className="signIn_container">
            <button className="signInButton" onClick={signIn}>Sign in with Google</button>
        </div>
    );
}

function SignOut(){
    return auth.currentUser && (
        <button className="signOut" onClick={() => auth.signOut()}><img src={signOut} alt="Sign Out"/></button>
    )
}

function LiveChat(){

    const [showChat, setShowChat] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [user] = useAuthState(auth);

    useEffect(() => {

        if (user && user.uid === "5Drmu8wi54VwtelKQRAhWkbyM1r1") {
            setAdmin(true);
        } else {
            setAdmin(false);

            if(user){
                const displayName = user.displayName;
                const userEmail = user.email;

                const docRef = firestore.collection('chat').doc(user.uid);
                docRef.get().then((docSnapshot) => {
                    if (!docSnapshot.exists){
                        docRef.set({
                            name: displayName,
                            email: userEmail,
                            latestMessage: "",
                        });
                    }
                });
            }
        }
    }, [user]);

    const toggleChat = () => {
        setShowChat(prevState => !prevState);
    };

    const adminClass = isAdmin ? "admin" : '';

    return(
        <div className="chat-container">
            {showChat ? (
                <>
                    <div className= {`chat_box ${adminClass}`}>
                        <div className="header">
                            <SignOut/>  
                            <button className="close_chat" onClick={toggleChat}><img src={arrowDown} alt="X"/></button>
                        </div>

                        {user ? (isAdmin ? <AllMessages /> : <ChatRoom uid={user.uid}/>) : <SignIn />}

                    </div>
                </>
            ) : (
                <div className="icon">
                    <LiveChatIcon onClick={toggleChat}/>
                </div>
            )}
        </div>
    );
}

export default LiveChat;




                

                