import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBVI9vJvZzgrxOLJNJjQgFohsOi8z3IbM4",
    authDomain: "livechat-3b1c5.firebaseapp.com",
    databaseURL: "https://livechat-3b1c5-default-rtdb.firebaseio.com",
    projectId: "livechat-3b1c5",
    storageBucket: "livechat-3b1c5.appspot.com",
    messagingSenderId: "674002654641",
    appId: "1:674002654641:web:16500b62348bf96f3ed990",
    measurementId: "G-JGJ96FMCC2"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const firestore = app.firestore();

export { auth, firestore};

