import './App.css';
import { Landing, Navbar, AboutMe, Technologies, Portfolio, Contact, Timeline} from './screens';
import { LiveChat } from './screens/components';

function App() {
  return (
    
    <div>
      <Navbar />
      <Landing />
      <AboutMe />
      <Technologies />
      <Timeline/>
      <Portfolio />
      <Contact />
      <LiveChat/>
    </div>
    
  );
  
}

export default App;
