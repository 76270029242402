// import jewelryWebsite from "./images/jewelryWebsite.jpg";
import resume from "./images/resume.jpg";
import resumePdf from "./images/Samuel Yuabov Resume.pdf";
import certificate from "./images/SamCertificate.png";

export const projects = [
    // {
    //     title: "Jewelry Website" ,
    //     description: "This is a test ecommerce website im working on for a jewelry store using HTML, CSS, JS, PHP and MySQL Database.",
    //     image: jewelryWebsite,
    //     url: "https://syuabovtest.000webhostapp.com/TestApp/index.php",
    //     id: "JeweleryWebsite"
    // },
    {
        title: "Resume",
        description: "This is my current resume",
        image: resume,
        url: resumePdf,
        id: "resume"
    },
    {
        title: "Omniado Internship",
        description: "I was tasked with building a web-based application for one of Omniado's clients in the credit card processing industry. To build this app I was required to use a mixture of coding languages including, PHP, Javascript, HTML, and CSS, in conjunction with implementing backend systems, such as WordPress CMS, CPanel server management, and PHPMyAdmin. This application will significantly enhance the client's onboarding process and eliminate the margin of error. This application was conceptualized built, and delivered within a five-week period. Furthermore, I developed a corporate website using React Library to increase brand awareness and enhance business development efforts.",
        image: certificate,
        url: "https://omniado.com",
        id: "OmInternship"
    }

]