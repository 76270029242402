import React, { useState, useEffect } from "react";
import "./Timeline.css";
import { timeline } from "./jobList";

function Timeline() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const timelineSection = document.querySelector("#timeline");
    const observerOptions = {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin
      threshold: 0.1, // Trigger when 10% of the timelineSection is visible
    };

    const intersectionCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // The timelineSection is now in view
          setIsActive(true);
          observer.unobserve(timelineSection); // Stop observing once the animation has been triggered
        }
      });
    };

    const observer = new IntersectionObserver(
      intersectionCallback,
      observerOptions
    );

    // Start observing the timelineSection
    observer.observe(timelineSection);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  const timelineList = timeline.map((timeline) => {
    return (
      <div
        className={`timeline_container ${isActive ? "active" : ""} ${
          timeline.className
        }`}
      >
        <img alt="" />
        <div className="timeline_text">
          <h2>{timeline.company}</h2>
          <h3>{timeline.title}</h3>
          <small>{timeline.date}</small>
          <p>{timeline.description}</p>
          <br />
          <p>{timeline.skills}</p>
          <span className={`timeline_arrow ${timeline.className}`}></span>
        </div>
      </div>
    );
  });

  return (
    <div className="timeline_section">
      <div id="timeline" className={`timeline  ${isActive ? "active" : ""}`}>
        {timelineList}
      </div>
    </div>
  );
}

export default Timeline;
