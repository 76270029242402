

export const timeline = [
    {
        className: "left-container",
        company: "Omniado",
        title: "Software Engineer",
        date: "8/23 - current",
        description: (<span>1. Designed <a style={{color: "black"}} target="_blank" rel="noreferrer" href="http://www.omniado.com">Omniado Website</a> using React Library.</span>),
        arrow: "left",
    },
    {
        className: "right-container",
        company: "Omniado",
        title: "Software Engineer Internship",
        date: "6/23 - 8/23",
        description: "I was tasked with building a web-based application for one of Omniado's clients in the credit card processing industry. This application will significantly enhance the client's onboarding process and eliminate the margin of error. This application was conceptualized built, and delivered within a five-week period. Furthermore, I developed a corporate website using React Library to increase brand awareness and enhance business development efforts.",
        skills: "Skills: WordPress · Data Science · Web Development · Software Development · Computer Science · Node.js · React.js · HTML5 · Cascading Style Sheets (CSS) · JavaScript · PHP",
        arrow: "right",
    },
    {
        className: "left-container",
        company: "Masri Digital",
        title: "IT Consultant",
        date: "1/23 - 6/23 ",
        description: "Design and Edit websites using 3rd party tools like Shopify, SquareSpace, Wix, and etc. Create professional emails for billing and information gathering. Create microsoft powerpoint and excel presentations for consumer use. IT technical support for multiple businesses.",
        arrow: "left",
    },
]