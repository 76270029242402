import { useState } from "react";
import "./Portfolio.css";
import { projects } from "./projects";
import { Modal } from "../components/index";

function Portfolio() {
  const [currentIdx, setCurrentIdx] = useState(null);

  const handleClick = (idx) => setCurrentIdx(idx);
  const clearCurrent = () => setCurrentIdx(null);

  const projectList = projects.map((project, idx) => {
    return (
      <div
        key={idx}
        onClick={() => handleClick(idx)}
        className="portfolio-projects-frame"
      >
        <h1 className="title">{project.title}</h1>
        <img id={project.id} src={project.image} alt={project.title} />
      </div>
    );
  });

  let currentProject = currentIdx >= 0 && projects[currentIdx];

  return (
    <>
      <div id="portfolio" className="portfolio-container">
        <h1 className="portfolio-title">Portfolio</h1>
        <p>
          This is my{" "}
          <a href="https://github.com/syuabov" target="_blank" rel="noreferrer">
            github
          </a>{" "}
          page and{" "}
          <a
            href="https://www.linkedin.com/in/samuel-yuabov-36461225b/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>{" "}
          page
        </p>
        <div className="portfolio-grid">
          {currentProject && (
            <Modal
              onClick={clearCurrent}
              title={currentProject.title}
              description={currentProject.description}
              image={currentProject.image}
              url={currentProject.url}
              id={currentProject.id}
            />
          )}
          {projectList}
        </div>
      </div>
      <hr
        width="80%"
        style={{ color: "black", border: "1px solid black", marginTop: "20px" }}
      ></hr>
      <div className="project-container">
        <h1 className="portfolio-title" style={{ margin: "10px 0px 20px 0px" }}>
          Projects
        </h1>
        <div className="project-grid">
          <div className="project-item">
            <h1 className="title" style={{ color: "white" }}>
              Live Chat Box (React)
            </h1>
            <p>
              Developed a live chat feature for my portfolio website. I integrated Firebase for Google Auth login and real-time user and message storage. For functionality, I used the React library react-firebase-hooks, and for styling, I used HTML/CSS. Additionally, I implemented the emailJS library to send messages when users are offline.            </p>
          </div>
          <div className="project-item">
            <h1 className="title" style={{ color: "white" }}>
              AI Model (Python)
            </h1>
            <p>
              Developed a sophisticated AI model utilizing Python, TensorFlow,
              and Keras, focusing on Convolutional Neural Networks (CNN) for
              image processing. The model employs various filters and
              convolution layers, implementing a supervised learning approach
              with an extensive database of structured images. This approach
              ensures the AI's effective learning and decision-making
              capabilities, adhering to a predetermined standard to select
              optimal images.
            </p>
          </div>
          <div className="project-item">
            <h1 className="title" style={{ color: "white" }}>
              Youtube Chat Bot (Python)
            </h1>
            <p>
              Designed and implemented a YouTube Live stream auto chat bot by
              leveraging YouTube API v3 and configuring Google Auth.
              Additionally, a parallel version of the bot was created using the
              Python Selenium library. The backend was built using Python's
              Flask framework to serve the frontend interface, which was
              constructed using HTML and CSS. This setup provides a seamless and
              user-friendly experience.
            </p>
          </div>
          <div className="project-item">
            <h1 className="title" style={{ color: "white" }}>
              My Portfolio Website (React)
            </h1>
            <p>
              Developed a dynamic, mobile-compatible portfolio website using
              React, HTML, and CSS to effectively showcase my skills, projects,
              and ongoing programming journey.
            </p>
            <h1 className="title" style={{ color: "white", marginTop: "10px" }}>
              Omniado Website (React)
            </h1>
            <p>
              Developed a dynamic, mobile-compatible corporate website using
              React Library, HTML, and CSS to increase brand awareness and
              enhance business development efforts (Designed based off a
              wireframe).
            </p>
          </div>
          <div className="project-item">
            <h1
              className="title"
              style={{ color: "white", textAlign: "center" }}
            >
              Wordpress Development (PHP/JavaScript)
            </h1>
            <p>
              Developed efficient admin dashboards, CRMs, ticket systems, and
              websites using WordPress CMS. Primarily working with PHP,
              JavaScript, HTML, and CSS, I customized extensive third-party
              plugins to align with clients' specific needs. Additionally, I
              crafted personalized plugins and integrations, leveraging large
              databases such as phpMyAdmin and Firebase. Utilizing APIs, I
              facilitated online payment processing and seamlessly integrated AI
              into websites to enhance functionality.
            </p>
          </div>
          <div className="project-item">
            <h1
              className="title"
              style={{ color: "white", textAlign: "center" }}
            >
              Translator App (React-Native){" "}
              <span style={{ marginLeft: "5px", color: "var(--main-brand)" }}>
                Work In Progress
              </span>
            </h1>
            <p>
              Developing an application designed to seamlessly detect and
              translate specified languages, utilizing google-text-to-speech,
              google-translate, and google-speech-to-text APIs to articulate the
              translated content through speech. Additionally, the app allows
              users to preserve conversations by offering a convenient save
              feature.
            </p>
          </div>
          <div className="project-item">
            <h1
              className="title"
              style={{ color: "white", textAlign: "center" }}
            >
              Invoice System (HTML/JavaScript/PHP/CSS)
            </h1>
            <p>
              Developed an invoice system enabling the store to generate
              invoices with inventory system access. Integrated a
              barcode-scanning feature via phone, fetching data from the
              inventory system through API calls to auto-fill products. Stored
              invoices in a PHPMyAdmin database, providing both admin and user
              access for future searches. Implemented a login system with
              password hashing, directing users based on their roles.
            </p>
          </div>
          <div className="project-item">
            <h1
              className="title"
              style={{ color: "white", textAlign: "center" }}
            >
              Hangman Game (Java)
            </h1>
            <p>
              Developed a simple Hangman game using Java, combining
              functionality with an intuitive graphical user interface (GUI)
              crafted with JFrame.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Portfolio;
